import { NumberValueAccessor } from "@angular/forms";

export enum AssociationType {
  InventoryItem = "InventoryItem",
  CatalogItem = "CatalogItem",
  TypeDefinition = "TypeDefinition",
}

export enum AssociatedItemStatus {
  NotAvailable = "NotAvailable",
  Available = "Available",
}

export class AssociatedItem {
  inventoryItemId: string;
  accountId: string;
  associationType: string;
  childItemId: string;
  id?: string;
  includedPrice?: number;
  isActive?: boolean;
  isFixed?: boolean;
  parentItemId: string;
  partitionKey?: string;
  price?: number;
  status: string;
  automaticAttachment: boolean;
  buyItNowPrice: number;
  purchasePrice: number;
}
