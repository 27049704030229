export interface INavigationItem {
  label: string;
  route?: any;
  externalLink?: any;
  fragment?: string;
  children?: NavigationItem[];
  queryParams?: {};
  icon?: string;
  active?: boolean;
  click?: any;
}

export class NavigationItem {
  label: string;
  route?: any;
  externalLink?: any;
  fragment?: string;
  children?: NavigationItem[];
  queryParams?: {};
  icon?: string;
  active?: boolean;
  click?: () => void;

  constructor(fields?: INavigationItem) {
    this.label = fields && fields.label ? fields.label : "";
    this.route = fields && fields.route ? fields.route : null;
    this.fragment = fields && fields.fragment ? fields.fragment : "";
    this.children = fields && fields.children ? fields.children : null;
    this.queryParams = fields && fields.queryParams ? fields.queryParams : null;
  }
}
