import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { fadeInOut } from "../../../rubbl-shared/animations";
import { EnvironmentConfig, LogService, MarketTrackingEvents, ModalService, ShareService } from "../../../core/core";
import { InventoryItem } from "../../../inventory/models/inventory-item.model";
import { Attachment } from "../../../inventory/models/attachment.model";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { PaginationOptions } from "swiper/types/components/pagination";
import { InventoryService } from "../../../inventory/inventory";

declare const environment: EnvironmentConfig;

@Component({
  selector: "common-buy-only-machine-card-advanced",
  templateUrl: "./buy-only-machine-card-advanced.component.html",
  styleUrls: ["./buy-only-machine-card-advanced.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut("250ms", "250ms")],
})
export class BuyOnlyMachineCardAdvancedComponent implements OnInit {
  @Input() machine: InventoryItem;
  @Input() filteredAttachments: string[] = [];

  MarketTrackingEvents = MarketTrackingEvents; // I may still need this? Tracks other things besides add to cart?
  fixedAttachments: Attachment[] = [];
  nonFixedAttachments: Attachment[] = [];
  availableAndSelected: Attachment[] = [];

  showExtraInfo: boolean = false;
  extraInfoHeight: string;
  baseImagePath = environment.baseImagePath;
  hasStockPhotos: boolean = false;

  @ViewChild("card") card;

  extraInfoIndex = 0;
  paginationConfig: PaginationOptions = {
    type: "bullets",
    el: ".swiper-pagination",
    bulletActiveClass: "swiper-pagination-bullet-active active-bullet",
    bulletClass: "swiper-pagination-bullet inactive-bullet",
    clickable: true,
    modifierClass: "swiper-pagination-bullets bottom-0",
  };

  extraInfoConfig: SwiperConfigInterface = {
    pagination: this.paginationConfig,
  };

  imageSwipeConfig: SwiperConfigInterface = {
    loop: true,
    pagination: this.paginationConfig,
    scrollbar: false,
    navigation: true,
    lazy: {
      preloaderClass: "preloader",
    },
  };
  machineDetailsUrl: string;

  constructor(
    private modalService: ModalService,
    private cd: ChangeDetectorRef,
    private inventoryService: InventoryService,
    private shareService: ShareService,
    private logService: LogService,
  ) {}

  ngOnInit() {
    console.log("Machine: ", this.machine);
    if (this.machine.thumbnails?.length === 1) {
      this.imageSwipeConfig.pagination = false;
      this.imageSwipeConfig.navigation = false;
    }
    const firstThumbNail = this.machine?.thumbnails[0];
    if (firstThumbNail && firstThumbNail.includes("pubweb/catalog")) {
      this.hasStockPhotos = true;
    }

    this.machineDetailsUrl = this.inventoryService.machineDetailsUrl(this.machine, false);

    // Filter only available attachments and distinct by display name
    this.machine.relatedAttachments = this.machine.relatedAttachments.filter(
      (value, index, self) => index === self.findIndex(t => t.displayName === value.displayName),
    );

    // Figure out what is fixed on the attachments
    this.fixedAttachments = this.machine.relatedAttachments.filter(d => d.isFixed);
    this.nonFixedAttachments = this.machine.relatedAttachments.filter(d => !d.isFixed);

    let installFirst = true;
    for (const filteredAttachment of this.filteredAttachments) {
      const attachment = this.machine.relatedAttachments.find(d => d.size === filteredAttachment);
      if (!attachment) {
        continue;
      }

      // If we have a filtered attachment, select it
      attachment.selected = true;

      // Install the first one
      if (installFirst) {
        attachment.install = true;
        installFirst = false;

        // If the attachment is standard, set the cost to 0
        if (attachment.standard) {
          attachment.price = attachment.cost = 0;
        }
      }

      this.availableAndSelected.push(attachment);
    }
  }


  showExtraInfoclick(index: number = 0) {
    this.inventoryService.counterUp(this.machine).subscribe();
    this.imageSwipeConfig.pagination = false;
    this.extraInfoHeight = this.card.nativeElement["offsetHeight"] + 10 + "px";
    this.showExtraInfo = true;

    this.extraInfoIndex = index;

    const modalBody = document.querySelector(".modal-body");
    if (modalBody) {
      modalBody.addEventListener(
        "scroll",
        e => {
          this.hideExtraInfo();
        },
        { once: true },
      );
    } else {
      // Add event listener to scroll
      document.addEventListener(
        "scroll",
        e => {
          this.hideExtraInfo();
        },
        { once: true },
      );
    }
  }

  hideExtraInfo() {
    this.imageSwipeConfig.pagination = this.paginationConfig;
    this.showExtraInfo = false;
    this.cd.detectChanges();
  }

  showStockPhotoInfo() {
    const hasAttachments = this.fixedAttachments.length || this.nonFixedAttachments.length;
    if (hasAttachments) {
      this.showExtraInfoclick(2);
    } else {
      this.showExtraInfoclick(1);
    }
  }

  share() {
    this.shareService.share(
      `Check out this ${this.machine.make} on Rubbl!`,
      `A ${this.machine.year} ${this.machine.make} ${this.machine.model} near ${this.machine.location.name}.`,
      this.inventoryService.machineDetailsUrl(this.machine),
      { target: "searchResults-machineShare" },
    );
  }
}
