import { CountValueFacetItem, Facet } from "./facet.model";

export class CountValueFacet extends Facet {
  constructor(name: string, facets: CountValueFacetItem[], label?: string) {
    super(name, facets, label);
  }

  get selected(): boolean {
    return this.facets.some(d => d.checked);
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);

    if (!param) {
      this.uncheck();
      return null;
    }

    const filters = [];
    const selectedValues = param.split(":");

    if (this.name == "accountId") {
      for (let selectedValue of selectedValues) {
        filters.push(`${this.name} eq '${selectedValue}'`);
      }

      return filters.length ? `(${filters.join(" or ")})` : null;
    }

    if (this.name == "excludeAccountId") {
      for (let selectedValue of selectedValues) {
        filters.push(`accountId ne '${selectedValue}'`);
      }

      return filters.length ? `(${filters.join(" or ")})` : null;
    }

    for (let facet of this.facets) {
      if (selectedValues.includes(facet.value)) {
        facet.checked = true;
        filters.push(`${this.name} eq '${facet.value}'`);
      } else {
        facet.checked = false;
      }
    }

    return filters.length ? `(${filters.join(" or ")})` : null;
  }

  update = (facets: CountValueFacetItem[]) => {
    if (this.selected) {
      // if this is selected  we only need to update the counts
      for (const facet of facets) {
        let correspondingFacets = this.facets.find(d => d.value == facet.value);
        if (correspondingFacets != null) {
          correspondingFacets.count = facet.count;
        }
      }
      return;
    }

    this.facets = facets;
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return this.facets
      .filter(d => d.checked)
      .map(d => d.value)
      .join(":");
  };
}
