import { Component, Input, OnInit } from "@angular/core";
import { InventoryItem } from "../../../inventory/inventory";
import { AzureSearchRequest, AzureSearchService } from "../../../azure-search/azure-search";

@Component({
  selector: "common-featured-machines-carousel",
  templateUrl: "./featured-machines-carousel.component.html",
})
export class FeaturedMachinesCarouselComponent implements OnInit {
  @Input() filter: string = "status eq 'Available'"
  azureSearchRequest = new AzureSearchRequest();
  machines: any[] = [];

  slidesPerViewConfig = {
    mobile: 1,
    tablet: 2,
    desktop: 2,
    "large-desktop": 3,
    "extra-large-desktop": 3,
    "extra-extra-large-desktop": 4,
  };

  constructor(private azureSearchService: AzureSearchService) { }

  ngOnInit() {
    this.azureSearchRequest.search = "*";
    this.azureSearchRequest.filter = this.filter;

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, "machines").subscribe((result) => {
      for (let inventoryItem of result.value) {
        this.machines.push({ machine: inventoryItem });
      }
    });
  }
}
