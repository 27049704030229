import { Location } from "../../core/models/location.model";
import { AuditMetadata } from "../../core/core";
import { Specification } from "./machine-type.interface";

export class Attachment {
  id: string;
  title: string;
  inventoryItemId: string;
  accountId: string;
  auditMetadata: AuditMetadata;
  catalogItemId: string;
  displayName: string;
  indexKey?: string;
  inventoryItemType: string;
  isActive: boolean;
  isPublic: boolean;
  itemType: string[];
  location: Location;
  make: string;
  model: string;
  partitionKey: string;
  position: string;
  primaryMeasurement?: Specification;
  primaryType?: string;
  propertyTag?: string;
  rentalRate: number;
  serialNumber: string;
  size?: string;
  specifications: string[] | Specification | any;
  status: "Available" | "Unavailable" | "Onboarding" | "Archived" | "OnRent";
  typeDefinitionName?: string;
  typeDefinitionId: string;
  cost: number;
  price: number;
  isFixed: boolean;
  standard: boolean;
  selected: boolean;
  install: boolean;
  compatibleWith: string[]; // this is a list of compatable attachment type ids
  description: string; // front end generated. normally its the specs formatted
  childItemId: string; // this is to make it work with the cart api. it is the id of this attachment
  compatibleCatalogItems: CompatibleCatalogItem[];
  associatedItems: any;
  alreadyInCart: boolean;
  buyItNowPrice: number;
  buyItNowEnabled: boolean;
  rpoEnabled: boolean;
  purchasePrice: number;
  

  constructor() {
    this.rentalRate = 0;
  }
}

export class CompatibleCatalogItem {
  catalogItemId: string;
  displayName: string;
  attachmentRentalRate: string;
}
